@font-face {
    font-family: 'SFNEO';
    font-weight: 400;
    src: url('./Fonts/AppleSDGothicNeoM.eot');
    src: url('./Fonts/AppleSDGothicNeoM.woff') format('woff');
}

@font-face {
    font-family: 'EMOJI';
    src: url('./Fonts/AppleEmoji.eot');
    src: url('./Fonts/AppleEmoji.woff') format('woff');
    src: url('./Fonts/AppleEmoji.woff2') format('woff2');
    src: url('./Fonts/AppleEmoji.ttf') format('truetype');
    src: url('./Fonts/AppleEmoji.svg#Apple Color Emoji') format('svg');
}

body{
    margin:0;
}

*{
    font-family: 'SFNEO';
}